import React from "react";
import { AppProvider } from "./providers/app-provider";
import { Routes } from "routing/routes";
import { setTranslation } from "localization/localization";
import { Alerts } from "wes_shell_app/alerts";

function App() {
  React.useMemo(() => {
    setTranslation();
  }, []);

  return (
    <AppProvider>
      <Alerts />
      <Routes />
    </AppProvider>
  );
}

export default App;
