import { environment } from "environment";
import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";
import { IServerCreateSortPlansRequestModel } from "models/server-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { updateItem } from "wes_shell_app/api-utils";

const store = new SharedContextStore();
const currentStationId = store.appContext.currentStationId;

export class DetailSortPlansRequestDataProvider extends SingleDataStoreProvider<IServerCreateSortPlansRequestModel> {
  sortPlansStore: any;

  constructor(useSortPlansStore) {
    super();
    this.sortPlansStore = useSortPlansStore;
  }

  get endpointUrl(): string {
    return `ui/getSortPlanDetails/${this.sortPlansStore.selectSortPlanID}`;
  }
  protected serviceApiUrl = environment.serviceApi;

  async updateDetailSortPlan(value: string, id: number, param: string) {
    const response = await updateItem(
      `${environment.serviceApi}ui/updateSortPlanDetail/${id}?${param}=${value}`
    );
    return response;
  }

  get defaultModel() {
    //TODO: workaround for initialization of store
    return {
      stationId: currentStationId,
    };
  }
}
