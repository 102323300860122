import { environment } from "environment";
import { IAPIWaveModel } from "models/server-models";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export type IWavesFilterParams = "state" | "type" | "createdTo" | "createdFrom";

export class WavesDataTableProvider extends DataTableStore<
  IAPIWaveModel,
  IWavesFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();
  get endpointUrl(): string {
    return `ui/getWaves/${this.sorterId}`;
  }

  protected serviceApiUrl = environment.serviceApi;
  get sorterId() {
    return this.sharedContextStore.appContext.currentStationId;
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setDate(timeFrom.getDate() - 1);
    return timeFrom;
  }

  get defaultFilter() {
    return new Map<IWavesFilterParams, any>([["state", "created"]]);
  }

  get filterDependencies(): Map<IWavesFilterParams, IWavesFilterParams[]> {
    return new Map<IWavesFilterParams, IWavesFilterParams[]>();
  }
}
