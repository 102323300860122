import { Dialog } from "common/base-components/dialog/dialog";
import { observer } from "mobx-react";
import { IClientSortPlanModel } from "models/client-models";
import { useState } from "react";
import ModelDialog, { IWithModalDialog } from "wes_shell_app/model-dialog";
import { useSortPlansStore } from "../hooks/use-sort-plans-store";
import { SortPlansRequestDataProvider } from "../stores/data/sort-plans-request-data-provider";
import { IServerCreateSortPlansRequestModel } from "models/server-models";
import { SortPlansDialogForm } from "./sort-plans-dialog-form";

type ISortPlansDialogFormProps = IWithModalDialog<IClientSortPlanModel>;

export const SortPlansModelDialogForm = observer(
  (props: ISortPlansDialogFormProps) => {
    const [_patchedItem, setPatchedItem] =
      useState<IClientSortPlanModel | null>(null);

    const store = useSortPlansStore();
    const provider = new SortPlansRequestDataProvider(store);

    if (!store.isAdmin) return null;

    return (
      <>
        <ModelDialog
          intlId="addNewSortPlan"
          onSuccess={() => {
            store.setLoadingButton(false);
            store.forceReload();
            store.resetSortPlansName();
          }}
          onError={() => store.setLoadingButton(false)}
          beforeConfirmAction={() => store.setLoadingButton(true)}
          dialogTitleIntl={props.dialogTitleIntl}
          type={props.type}
          onCancel={() => store.resetSortPlansName()}
          defaultValue={
            provider.defaultModel as IServerCreateSortPlansRequestModel
          }
          provider={provider}
          loading={store.loadingButton}
        >
          <SortPlansDialogForm />
        </ModelDialog>
      </>
    );
  }
);
