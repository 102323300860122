import { Dialog } from "common/base-components/dialog/dialog";
import { observer } from "mobx-react";
import Button from "wes_shell_app/button";
import Text from "wes_shell_app/text";
import { useWavesStore } from "../../hooks/use-waves-store";

type waveStartDialogProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const SortingOutputsDialog = observer((props: waveStartDialogProps) => {
  const store = useWavesStore();
  if (!store.isLoaded) return null;

  const sortingOutputs = store.runWaveStore.sortingOutputs;

  return (
    <Dialog
      intlTitle="sortingOutputsTitle"
      open={props.isOpened}
      onClose={props.onClose}
      actions={[
        <Button
          color="primary"
          intlId="yes"
          variant="contained"
          onClick={props.onConfirm}
        />,
      ]}
    >
      <Text
        intlId="sortingOutputsContent"
        intlParams={{
          count: sortingOutputs && sortingOutputs.unassignedContainers,
        }}
      />
    </Dialog>
  );
});
