import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import { useRouting } from "wes_shell_app/use-routing";
import DashboardView from "features/dashboard/views/dashboard-view";
import InductionStationSelectView from "features/induction-station/views/induction-station-select-view";
import WavesView from "features/waves/views/waves-view";
import WaveDetailView from "features/wave-detail/view/wave-detail-view";
import { useNavigationStore } from "wes_shell_app/use-navigation-store";
import { observer } from "mobx-react";
import { useInductionStationViewStore } from "features/induction-station/hooks/use-induction-station-view-store";
import { basePath, menu, RoutePaths } from "./menu";
import InductionStationViewWrapper from "features/induction-station/views/wrappers/induction-station-view-wrapper";
import SortPlansView from "features/sort-plans/views/sort-plans-view";
import { useSettingsStore } from "wes_shell_app/use-setting-store";
import SettingView from "features/settings/views/setting-view";
import { useSettingViewStore } from "features/settings/hooks/use-setting-store";
import WaveCorrectionsView from "features/wave-corrections/views/wave-corrections-view";
import PlatformView from "features/platform/views/platform-view";
import ChartsView from "features/charts/views/charts-view";
import { useHandBarcodeScanner } from "features/induction-station/hooks/use-hand-barcode-scanner";
import useAppContext from "wes_shell_app/use-app-context";

export const Routes = observer(() => {
  const inductionStore = useInductionStationViewStore();
  const navStore = useNavigationStore();
  const settingsStore = useSettingsStore();
  const settingViewStore = useSettingViewStore();
  const sharedContextStore = useAppContext();
  const routerStore = useRouting();

  useHandBarcodeScanner();

  const inductionStations = inductionStore.actualInductionStations
    .flatMap((station) => station.inductionStations)
    .map((station) => station);

  React.useEffect(() => {
    routerStore.addPaths([...Object.values(RoutePaths)], basePath);
    inductionStore.setInductionStations();
    settingViewStore.getSettingModules();
    settingViewStore.getSettingComponents();
  }, []);

  React.useEffect(() => {
    navStore.setItems(menu(inductionStations).groups);
  }, [inductionStations]);

  React.useEffect(() => {
    settingsStore.setActiveComponent(SettingView(settingViewStore));

    return () => {
      settingsStore.resetActiveComponent();
    };
  }, [settingViewStore.modules, settingViewStore.components]);

  return (
    <ReactRoutes>
      <Route
        path={RoutePaths.home}
        element={
          sharedContextStore.sharedContext.appContext.accessRights
            .isAdminRole ||
          sharedContextStore.sharedContext.appContext.accessRights
            .isLeaderRole ? (
            <DashboardView />
          ) : (
            <InductionStationSelectView />
          )
        }
      />
      <Route path={RoutePaths.charts} element={<ChartsView />} />
      <Route
        path={RoutePaths.inductionStation}
        element={<InductionStationViewWrapper />}
      />
      <Route path={RoutePaths.waves} element={<WavesView />} />
      <Route path={RoutePaths.waveDetail} element={<WaveDetailView />} />
      <Route path={RoutePaths.sortPlan} element={<SortPlansView />} />
      <Route path={RoutePaths.corrections} element={<WaveCorrectionsView />} />
      <Route path={RoutePaths.platform} element={<PlatformView />} />
    </ReactRoutes>
  );
});
