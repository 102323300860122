import { observer } from "mobx-react";
import { IClientSortPlanModel } from "models/client-models";
import { useState } from "react";
import { IWithModalDialog } from "wes_shell_app/model-dialog";
import { useSortPlansStore } from "../hooks/use-sort-plans-store";
import { SortPlansRequestDataProvider } from "../stores/data/sort-plans-request-data-provider";
import { SortPlansCloneDialogForm } from "./sort-plans-clone-dialog-form";
import { Button } from "wes_shell_app/button";
import { Dialog } from "wes_shell_app/dialog";

type ISortPlansDialogFormProps = IWithModalDialog<IClientSortPlanModel>;

export const SortPlansCloneModelDialogForm = observer(
  (props: ISortPlansDialogFormProps) => {
    const [isOverrideDialogVisible, setOverrideDialogVisibility] =
      useState(false);
    const store = useSortPlansStore();

    if (!store.isAdmin) return null;

    const handleCloneSortPlan = async () => {
      const response = await store.createCloneSortPlan(
        store.selectedCloneSortPlan,
        store.newSortPlansName
      );

      if (!response) return;

      store.setSortPlansName("");
      store.forceReload();
      store.resetSortPlanTableRowSelected();
      store.setSortPlanCloneButton(false);
      setOverrideDialogVisibility(false);
      store.resetSelectedCloneSortPlan(true);
    };

    const handleCloneSortPlanCancel = () => {
      store.setSortPlansName("");
      setOverrideDialogVisibility(false);
    };

    return (
      <>
        <Dialog
          open={isOverrideDialogVisible}
          cancelAction={handleCloneSortPlanCancel}
          cancelLabel="cancel"
          confirmAction={handleCloneSortPlan}
          confirmLabel="save"
          intlTitle={"cloneSortPlan"}
          loadingButton
          loading={store.loadingButton}
        >
          <SortPlansCloneDialogForm />
        </Dialog>
        <Button
          variant={"contained"}
          intlId={"clone"}
          onClick={() => setOverrideDialogVisibility(true)}
        />
      </>
    );
  }
);
