import { environment } from "environment";
import { makeObservable, observable } from "mobx";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import routerStore from "wes_shell_app/router-store";

export type IOrdersFilterParams = "searchExternalId" | "searchLine";

export class OrdersDataTableProvider extends DataTableStore<
  any,
  IOrdersFilterParams
> {
  constructor() {
    super();
    makeObservable(this, {
      currentWaveId: observable,
    });
  }

  readonly sharedContextStore = new SharedContextStore();
  get endpointUrl(): string {
    return `ui/getWaveOrders/${this.currentWaveId}`;
  }

  protected serviceApiUrl = environment.serviceApi;
  currentWaveId: number = this.defaultWaveId;

  private get defaultWaveId(): number {
    const currentLocationArray: string[] =
      routerStore.location.pathname.split("/");
    const lastPathMember: string =
      currentLocationArray[currentLocationArray.length - 1];
    return parseInt(lastPathMember);
  }

  get defaultFilter() {
    return new Map<any, any>();
  }

  get filterDependencies(): Map<IOrdersFilterParams, IOrdersFilterParams[]> {
    return new Map<IOrdersFilterParams, IOrdersFilterParams[]>();
  }
}
