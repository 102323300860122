import { observer } from "mobx-react";
import { SettingsViewStore } from "../stores/settings-view-store";
import { Info } from "@mui/icons-material";
import Button from "wes_shell_app/button";
import { useState } from "react";
import { IAPISettingComponentsContent } from "models/server-models";
import Text from "wes_shell_app/text";
import SettingsServicesInfoDialog from "./dialogs/settings-services-info-dialog";
import SettingsServicesResultDialog from "./dialogs/settings-services-result-dialog";
import SettingsServicesTestDialog from "./dialogs/settings-services-test-dialog";
import { WaveRunningStore } from "stores/common/wave-running-store";
import SettingsServicesStopDialog from "./dialogs/settings-services-stop-dialog";

type ServicesModuleProps = {
  store: SettingsViewStore;
};

const SettingServicesTesting = observer((props: ServicesModuleProps) => {
  const { store } = props;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDialogResult, setShowDialogResult] = useState<boolean>(false);
  const [showDialogInfo, setShowDialogInfo] = useState<boolean>(false);
  const [showStopDialog, setShowStopDialog] = useState<boolean>(false);
  const waveRunningStore = new WaveRunningStore();

  const handleServiceTesting = async () => {
    await store.handleComponentTest("start");
    setShowDialog(false);
    setShowDialogResult(true);
  };

  const handleServiceTestingResult = async (endStatus: string) => {
    await store.handleComponentTest("stop", endStatus);
    setShowDialogResult(false);
  };

  const handleSelectedComponent = async (
    component: IAPISettingComponentsContent
  ) => {
    await waveRunningStore.getWaveRunning();

    if (waveRunningStore.waveRunning?.id) {
      setShowStopDialog(true);
    } else {
      store.setSelectedComponent(component);
      setShowDialog(true);
    }
  };

  return (
    <>
      <SettingsServicesStopDialog
        open={showStopDialog}
        onClose={() => setShowStopDialog(false)}
      />
      <SettingsServicesTestDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onConfirm={handleServiceTesting}
        component={store.selectedComponent}
      />
      <SettingsServicesResultDialog
        open={showDialogResult}
        onClose={() => setShowDialogResult(false)}
        onClick={handleServiceTestingResult}
        component={store.selectedComponent}
      />
      <SettingsServicesInfoDialog
        open={showDialogInfo}
        onClose={() => setShowDialogInfo(false)}
        component={store.selectedComponent}
      />
      <div className="flex flex-col space-y-6">
        {store.components.length > 0 ? (
          store.components.map((component, index) => (
            <div key={component.uuid} className="flex justify-between">
              <Button
                variant="contained"
                intlId={component.name}
                onClick={() =>
                  handleSelectedComponent(
                    component as IAPISettingComponentsContent
                  )
                }
              />
              <Button
                variant="icon"
                onClick={() => {
                  store.setSelectedComponent(component);
                  setShowDialogInfo(true);
                }}
                icon={<Info color="primary" />}
              />
            </div>
          ))
        ) : (
          <Text intlId="componentsNoToTest" />
        )}
      </div>
    </>
  );
});

export default SettingServicesTesting;
