import { Dialog } from "common/base-components/dialog/dialog";
import Button from "wes_shell_app/button";
import Text from "wes_shell_app/text";

export type SortPlansUnsortedOrdersDialogProps = {
  open: boolean;
  onClose: () => void;
  store: any;
  handleUnsortedOrdersDialog: (addOrders: boolean) => void;
};

const SortPlansUnsortedOrdersDialog = (
  props: SortPlansUnsortedOrdersDialogProps
) => {
  const { open, onClose, store, handleUnsortedOrdersDialog } = props;

  return (
    <Dialog
      open={open}
      intlTitle="unsortedOrdersTitle"
      onClose={onClose}
      actions={[
        <Button
          intlId={"yes"}
          onClick={() => handleUnsortedOrdersDialog(true)}
          key={1}
          color="primary"
          variant="contained"
        />,
        <Button
          intlId={"no"}
          onClick={() => handleUnsortedOrdersDialog(false)}
          key={2}
          color="primary"
          variant="outlined"
        />,
      ]}
    >
      <Text
        intlId="unsortedOrdersContent"
        intlParams={{
          numberOfOrders: store.unsortedOrders,
          waveName: store.unsortedOrdersWaveName,
        }}
      />
    </Dialog>
  );
};

export default SortPlansUnsortedOrdersDialog;
