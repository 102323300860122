import { MobXProviderContext } from "mobx-react";
import * as React from "react";
import { RootStore } from "stores/root-store";

type IAppMobxContext = {
  rootStore: RootStore;
};

export function useAppMobxContext() {
  return React.useContext<IAppMobxContext>(MobXProviderContext as any);
}
