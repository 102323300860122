import {
  KeyboardArrowUp,
  ExpandMore,
  PriorityHigh,
  Check,
} from "@mui/icons-material";
import { TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import Big from "wes_shell_app/big";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { SimpleCard } from "wes_shell_app/simpleCard";
import useConfirmDialog from "wes_shell_app/use-confirm-dialog";
import { useDashboardStore } from "../hooks/use-dashboard-store";
import { IWebsocketTableMessagesClientModelContent } from "models/client-models";
import { useFatalLogstore } from "wes_shell_app/use-fatal-log-store";
import { observer } from "mobx-react";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "time",
  },
  {
    label: "severity",
  },
  {
    label: "message",
  },
  {
    label: "confirmed",
  },
  {
    label: "module",
  },
];

const FatalDataTable = observer(() => {
  const [moreInfo, setMoreInfo] = useState(false);

  const wsStore = useDashboardStore();
  const apiStore = useFatalLogstore();
  const confirmDialog = useConfirmDialog();

  if (!wsStore.isLoaded) return null;

  return wsStore.fatalMessagesCount > 0 ? (
    <SimpleCard backgroundColor="#ffcdd2">
      <div
        className={
          wsStore.fatalMessagesCount > 1
            ? "flex justify-between cursor-pointer"
            : "flex justify-between"
        }
        onClick={
          wsStore.fatalMessagesCount > 1
            ? () => setMoreInfo((prev) => !prev)
            : () => {}
        }
      >
        <Big
          sx={{ fontWeight: "bold" }}
          intlId="fatalTableMessagesLabel"
          suffix={wsStore.fatalMessagesCount.toString()}
        />
        {moreInfo ? (
          <KeyboardArrowUp fontSize="large" />
        ) : (
          <ExpandMore fontSize="large" />
        )}
      </div>
      <DataTable
        isLoading={!apiStore.isLoaded}
        page={apiStore.page}
        onPageChange={apiStore.setPage}
        onDirectionChange={apiStore.setDirection}
        direction={apiStore.direction}
        pageCount={apiStore.totalCount}
        pageSize={apiStore.pageSize}
        onPageSizeChange={apiStore.setPageSize}
        rows={moreInfo ? apiStore.items : wsStore.fatalMessagesDataTable}
        hidePaging
        columns={columns}
        rowTemplate={(item) => (
          <RowTemplate
            key={item.id}
            item={item}
            onConfirm={confirmDialog.open}
          />
        )}
      />
    </SimpleCard>
  ) : null;
});

type IRowTemplateProps = {
  item: IWebsocketTableMessagesClientModelContent;
  onConfirm: Function;
};

const RowTemplate = (props: IRowTemplateProps) => {
  console.log(props.item);
  const { issued, severity, message, appmoduleName, confirmed } = props.item;
  const confirmable = severity === "FATAL" && !confirmed;
  return (
    <TableRow
      className={confirmable ? "cursor-pointer" : ""}
      onClick={() => confirmable && props.onConfirm(props.item)}
      style={{ backgroundColor: "inherit" }}
    >
      <TableCell>{new Date(issued).toLocaleString()}</TableCell>
      <TableCell>{severity}</TableCell>
      <TableCell>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </TableCell>
      <TableCell>
        {confirmed ? <Check color="success" /> : <PriorityHigh color="error" />}
      </TableCell>
      <TableCell>{appmoduleName}</TableCell>
    </TableRow>
  );
};

export default FatalDataTable;
