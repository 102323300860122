import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import {
  ISortPlansFilterParams,
  responseCreateCloneSortPlan,
} from "../utils/sort-plans-utils";
import { SortPlansDataTableProvider } from "./data/sort-plans-data-table-provider";
import {
  IAPIcheckSortingOuputs,
  IAPIcheckSortingOuputsContent,
  IAPICheckUnsortedOrders,
  IAPIFilterRunWave,
  IAPIGetWaveSummary,
  IAPIGetWaveSummaryContent,
  IAPIReplaceContainers,
  IAPIReplaceContainersContent,
  IAPIStepPhysLocation,
  IAPIStepPhysLocationContent,
  IServerSortPlansModel,
} from "models/server-models";
import {
  IClientCloneSortPlanModel,
  IClientSortPlanModel,
} from "models/client-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { dataGET, dataPOST, updateItem } from "wes_shell_app/api-utils";
import { environment } from "environment";
import { action, computed, makeObservable, observable } from "mobx";
import { IAPICloneSortPlanResponsetModel } from "common/types/types";
import alerts from "wes_shell_app/alerts";
import { serverToClientSortPlansModel } from "models/mappers";

export class SortPlansViewStore extends BaseTableViewStore<
  IServerSortPlansModel,
  IClientSortPlanModel,
  ISortPlansFilterParams,
  SortPlansDataTableProvider
> {
  constructor() {
    super(new SortPlansDataTableProvider());
    makeObservable(this, {
      startSortPlan: observable,
      filterRunWave: observable,
      showDrawer: observable,
      newSortPlansName: observable,
      showedSortPlanCloneButton: observable,
      selectedCloneSortPlan: observable,
      canSelectRow: observable,
      drawer: observable,
      addUnsortOrders: observable,
      sortPlanSelectedWave: observable,
      waveSummary: observable,
      physLocation: observable,
      checkSortingOutputs: observable,
      replacedContainers: observable,
      sortplanTableRowSelected: observable,
      showRequiredMessage: observable,
      loadingButton: observable,
      setShowDrawer: action,
      setSortPlansName: action,
      setSortPlanCloneButton: action,
      resetSelectedCloneSortPlan: action,
      openDrawer: action,
      closeDrawer: action,
      setShowRequiredMessage: action,
      unsortedOrders: computed,
      unsortedOrdersWaveName: computed,
      currentUserStore: computed,
      isAdmin: computed,
      isLoaded: computed,
      defaultTimeFromValue: computed,
      isDrawerOpen: computed,
    });
  }

  private readonly appContext = new SharedContextStore();

  startSortPlan: IAPICheckUnsortedOrders | null = null;
  filterRunWave: IAPIFilterRunWave | null = null;
  showDrawer: boolean = false;
  selectedCloneSortPlan: IClientCloneSortPlanModel | null = null;
  newSortPlansName: string = "";
  showedSortPlanCloneButton: boolean = false;
  canSelectRow: boolean = true;
  selectedSortID: number | null = null;
  selectedSortPlan: IClientSortPlanModel | null = null;
  drawer: boolean = false;
  addUnsortOrders: boolean = false;
  sortPlanSelectedWave: number | null = null;
  waveSummary: IAPIGetWaveSummaryContent[] = [];
  physLocation: IAPIStepPhysLocationContent[] = [];
  checkSortingOutputs: IAPIcheckSortingOuputsContent[] = [];
  replacedContainers: IAPIReplaceContainersContent[] = [];
  automaticPhysicalAssignment: boolean = false;
  sortplanTableRowSelected: number | null = null;
  showRequiredMessage: boolean = false;
  loadingButton: boolean = false;

  setLoadingButton(value: boolean) {
    this.loadingButton = value;
  }

  setSortPlanTableRowSelected = (id: number) => {
    this.sortplanTableRowSelected = id;
  };

  resetSortPlanTableRowSelected = () => {
    this.sortplanTableRowSelected = null;
  };

  setAutomaticPhysicalAssignment() {
    this.automaticPhysicalAssignment = !this.automaticPhysicalAssignment;
  }

  resetAutomaticPhysicalAssignment() {
    this.automaticPhysicalAssignment = false;
  }

  setCanSelectRow(value: boolean) {
    this.canSelectRow = value;
  }

  setaddUnsortOrders(value: boolean) {
    this.addUnsortOrders = value;
  }

  resetAddUnsortOrders() {
    this.addUnsortOrders = false;
  }

  setSortPlanSelectedWave(value: number | null) {
    this.sortPlanSelectedWave = value;
  }

  setWaveSummaryData(value: IAPIGetWaveSummaryContent[]) {
    this.waveSummary = value;
  }

  async setStartSortPlan(sorterID: number) {
    try {
      const response: IAPICheckUnsortedOrders = await dataGET(
        `${environment.serviceApi}ui/checkUnsortedOrders/${sorterID}`
      );
      this.startSortPlan = response;
      if (this.unsortedOrders > 0) {
        return true;
      } else {
        return await this.filterRunWaveAPI();
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  async filterRunWaveAPI() {
    try {
      const response: IAPIFilterRunWave = await dataGET(
        `${environment.serviceApi}ui/filterRunWave/${this.appContext.appContext.currentStationId}`
      );
      this.setfilterRunWave(response);
      return true;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
      return false;
    }
  }

  async getWaveSummaryAPI() {
    try {
      const response: IAPIGetWaveSummary = await dataGET(
        `${environment.serviceApi}ui/getWaveSummary?sortPlanID=${this.selectedSortID}&waveID=${this.sortPlanSelectedWave}`
      );
      this.setWaveSummaryData(response.content);
      return true;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
      return false;
    }
  }

  async stepCheckPhysicalLocation() {
    try {
      const response: IAPIStepPhysLocation = await dataGET(
        `${environment.serviceApi}ui/checkLocationsForWave?sorterID=${this.appContext.appContext.currentStationId}&sortPlanID=${this.selectedSortID}&waveID=${this.sortPlanSelectedWave}`
      );
      this.setPhysLocation(response.content);
      return true;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
      return false;
    }
  }

  async stepCheckSortingOutputs() {
    try {
      const response: IAPIcheckSortingOuputs = await dataGET(
        `${environment.serviceApi}ui/checkSortingOutputs/${this.appContext.appContext.currentStationId}`
      );
      this.setCheckSortingOutputs(response.content);
      return true;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
      return false;
    }
  }

  async stepReplacedContainers() {
    try {
      const response: IAPIReplaceContainers = await dataGET(
        `${environment.serviceApi}ui/checkReplacedContainers?sorterID=${this.appContext.appContext.currentStationId}&sortPlanID=${this.selectedSortID}`
      );
      this.setReplacedContainers(response.content);
      return true;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
      return false;
    }
  }

  async createCloneSortPlan(
    selectedCloneSortPlan: IClientCloneSortPlanModel,
    newSortPlansName: string
  ) {
    this.setLoadingButton(true);
    try {
      const res: responseCreateCloneSortPlan = await dataPOST(
        `${environment.serviceApi}ui/createSortPlan/${this.appContext.appContext.currentStationId}?sortPlanID=${selectedCloneSortPlan.id}&name=${newSortPlansName}`
      );
      alerts.success({ message: res.message });
      this.setLoadingButton(false);
      return true;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
      this.setLoadingButton(false);
      return false;
    }
  }

  async startWaveAPI() {
    try {
      await updateItem(
        `${environment.serviceApi}ui/waveStart?sorterID=${this.appContext.appContext.currentStationId}&sortPlanID=${this.selectedSortID}&waveID=${this.sortPlanSelectedWave}&autoPhysAssignment=${this.automaticPhysicalAssignment}&addUnsortedOrders=${this.addUnsortOrders}`
      );
      this.resetAutomaticPhysicalAssignment();
      this.resetAddUnsortOrders();
      return {
        result: true,
        message: "waveIsRunningContent",
      };
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      return {
        result: false,
        message: errorModel,
      };
    }
  }

  setPhysLocation(physLocation: IAPIStepPhysLocationContent[]) {
    this.physLocation = physLocation;
  }

  setCheckSortingOutputs(checkSortingOutputs: IAPIcheckSortingOuputsContent[]) {
    this.checkSortingOutputs = checkSortingOutputs;
  }

  setReplacedContainers(containers: IAPIReplaceContainersContent[]) {
    this.replacedContainers = containers;
  }

  setfilterRunWave(value: IAPIFilterRunWave) {
    this.filterRunWave = value;
  }

  setShowDrawer(value: boolean) {
    this.showDrawer = value;
  }

  setSortPlansName(name: string) {
    this.newSortPlansName = name;
  }

  resetSortPlansName() {
    this.newSortPlansName = "";
  }

  setSortPlanCloneButton(value: boolean) {
    this.showedSortPlanCloneButton = value;
  }

  setSelectedCloneSortPlan(id: number, name: string) {
    this.selectedCloneSortPlan = { id, name };
  }

  resetSelectedCloneSortPlan(reset: boolean) {
    if (reset) {
      this.selectedCloneSortPlan = null;
    }
  }

  setSelectSortPlanID(id: number) {
    this.selectedSortID = id;
  }

  openDrawer = () => {
    this.drawer = true;
  };
  closeDrawer = () => (this.drawer = false);

  get isDrawerOpen() {
    return this.drawer;
  }

  get selectSortPlanID() {
    return this.selectedSortID;
  }

  get unsortedOrders() {
    return this.startSortPlan?.content[0].unsortedOrders;
  }
  get unsortedOrdersWaveName() {
    return this.startSortPlan?.content[0].waveName;
  }

  set setSelectSortPlan(selectedSortPlan: IClientSortPlanModel) {
    this.selectedSortPlan = selectedSortPlan;
    this.setSelectSortPlanID(selectedSortPlan.id);
  }

  setShowRequiredMessage = (value: boolean) =>
    (this.showRequiredMessage = value);

  mapServerToClientModel = (item): IClientSortPlanModel =>
    serverToClientSortPlansModel(item);

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
}
