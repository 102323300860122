import { observer } from "mobx-react";
import { useState } from "react";
import {
  PauseCircleOutline,
  PlayCircleOutline,
  CheckCircleOutline,
} from "@mui/icons-material";
import { useDashboardStore } from "../hooks/use-dashboard-store";
import { Box, FormLabel } from "@mui/material";
import Button from "wes_shell_app/button";
import DashboardWaveControlDialog from "./dialgos/dashboard-wave-control-dialog";
import { getTranslation } from "wes_shell_app/localization-utils";
import DashboardFinishWaveControlDialog from "./dialgos/dashboard-finish-wave-control-dialog";
import DashboardWaveControlResultDialog from "./dialgos/dashboard-wave-control-result-dialog";

type ButtonIconProps = {
  icon: React.ReactElement;
  type: string;
  status: string;
  onClick: () => void;
};

const ButtonIcon = ({ icon, type, status, onClick }: ButtonIconProps) => {
  const isDisabled =
    (status === "stopped" && type === "resume") ||
    (status === "stopped" && type === "finish") ||
    (status === "running" && type === "finish") ||
    (status === "running" && type === "pause")
      ? false
      : true;

  return (
    <Button
      icon={icon}
      disabled={isDisabled}
      onClick={onClick}
      sx={{ padding: "5px 0px" }}
    />
  );
};

const WaveControl = observer(() => {
  const [showWaveDialog, setShowWaveDialog] = useState(false);
  const [showFinishWaveDialog, setShowFinishWaveDialog] = useState(false);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [waveDialogTitle, setWaveDialogTitle] = useState("");
  const [waveDialogText, setWaveDialogText] = useState("");
  const [confirmLabel, setConfirmLabel] = useState("");
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [action, setAction] = useState("");

  const store = useDashboardStore();
  if (!store.isLoaded) return null;
  const status: string = store.headerInfoState;

  const handlerFinishWave = async () => {
    await store.waveSortedAPI();
    if (store.waveSorted === true) {
      handlerWave("finishWave");
    } else {
      setShowFinishWaveDialog(true);
      setAction("finishWave");
    }
  };

  const resetStates = () => {
    setWaveDialogTitle("");
    setWaveDialogText("");
    setConfirmLabel("");
    setResponseSuccess(false);
    store.setWaveControlResponseText("");
    setAction("");
  };

  const handlerWave = (action: string) => {
    if (action === "pauseWave") {
      setWaveDialogTitle("pauseSorter");
      setWaveDialogText("sorterWillPause");
      setConfirmLabel("pause");
      setAction(action);
    } else if (action === "finishWave") {
      setWaveDialogTitle("finishSorter");
      setWaveDialogText("sorterWillFinish");
      setConfirmLabel("finish");
      setAction(action);
    } else if (action === "resumeWave") {
      setWaveDialogTitle("resumeSorter");
      setWaveDialogText("sorterWillResume");
      setConfirmLabel("resume");
      setAction(action);
    } else {
      return;
    }
    setShowWaveDialog(true);
  };

  type controlWaveResponseType = {
    success: boolean;
    action: string;
  };

  const controlWave = async () => {
    setShowWaveDialog(false);
    setShowFinishWaveDialog(false);

    const response: controlWaveResponseType = await store.waveControl(
      store.waveID,
      action
    );

    setResponseSuccess(response.success);

    if (response.success) {
      if (action === "pauseWave") {
        store.setWaveControlResponseText("sorterPaused");
      } else if (action === "resumeWave") {
        store.setWaveControlResponseText("sorterResumed");
      } else {
        store.setWaveControlResponseText("sorterFinished");
      }
    } else {
      if (action === "pauseWave") {
        store.setWaveControlResponseText("sorterCanNotPause");
      } else if (action === "resumeWave") {
        store.setWaveControlResponseText("sorterCanNotResume");
      } else {
        store.setWaveControlResponseText("sorterCanNotFinished");
      }
    }

    setShowResultDialog(true);
  };

  return (
    <>
      <DashboardWaveControlResultDialog
        open={showResultDialog}
        onClose={() => {
          setShowResultDialog(false);
          setTimeout(() => {
            resetStates();
          }, 800);
        }}
        intlText={store.waveControlResponseText}
        success={responseSuccess}
      />
      <DashboardWaveControlDialog
        open={showWaveDialog}
        onClose={() => setShowWaveDialog(false)}
        onConfirm={() => controlWave()}
        intlTitle={waveDialogTitle}
        intlText={waveDialogText}
        confirmLabel={getTranslation(confirmLabel)}
      />
      <DashboardFinishWaveControlDialog
        open={showFinishWaveDialog}
        onClose={() => setShowFinishWaveDialog(false)}
        onConfirm={() => controlWave()}
      />
      {status && (
        <FormLabel sx={{ fontSize: "0.75em" }}>
          <Box
            sx={{
              backgroundColor: (() => {
                switch (status) {
                  case "stopped":
                    return "#ffcc80";
                  case "running":
                    return "#a5d6a7";
                  case "emergency":
                    return "#ef9a9a";
                  default:
                    return "#ffff";
                }
              })(),
            }}
            className="rounded p-1 text-center"
          >
            <p className="font-bold text-sm">
              {(() => {
                switch (status) {
                  case "stopped":
                    return getTranslation("stopped").toUpperCase();
                  case "running":
                    return getTranslation("running").toUpperCase();
                  case "emergency":
                    return getTranslation("emergencyStop").toUpperCase();
                  default:
                    return getTranslation("").toUpperCase();
                }
              })()}
            </p>
          </Box>
        </FormLabel>
      )}
      <div className="inline-flex flex-row flex-wrap justify-center w-full min-h-[4.5rem] items-center">
        <ButtonIcon
          icon={<PauseCircleOutline sx={{ fontSize: 45 }} />}
          type="pause"
          status={status}
          onClick={() => handlerWave("pauseWave")}
        />
        <ButtonIcon
          icon={<PlayCircleOutline sx={{ fontSize: 45 }} />}
          type="resume"
          status={status}
          onClick={() => handlerWave("resumeWave")}
        />
        <ButtonIcon
          icon={<CheckCircleOutline sx={{ fontSize: 45 }} />}
          type="finish"
          status={status}
          onClick={() => handlerFinishWave()}
        />
      </div>
    </>
  );
});

export default WaveControl;
