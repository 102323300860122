declare global {
  interface Window {
    _env_: any;
  }
}

const environmentVariables = window._env_;

export const environment = {
  serviceApi: environmentVariables.REACT_APP_SORTER_SERVICE_API,
  inductionStationApi: environmentVariables.REACT_APP_SORTER_INDUCTION_API,
  appWss: environmentVariables.REACT_APP_SORTER_DASHBOARD_WSS,
  inductionStationWss:
    environmentVariables.REACT_APP_SORTER_INDUCTION_STATION_WSS,
  platformWss: environmentVariables.REACT_APP_SORTER_PLATFORM_WSS,
};
