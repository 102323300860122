import { Dialog } from "common/base-components/dialog/dialog";
import { observer } from "mobx-react";
import Button from "wes_shell_app/button";
import Text from "wes_shell_app/text";
import { useWavesStore } from "../../hooks/use-waves-store";

type waveStartDialogProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const UnsortedOrdersDialog = observer((props: waveStartDialogProps) => {
  const store = useWavesStore();
  if (!store.isLoaded) return null;

  const unsortedOrders = store.runWaveStore.unsortedOrders;

  return (
    <Dialog
      intlTitle="unsortedOrdersTitle"
      open={props.isOpened}
      onClose={props.onClose}
      actions={[
        <Button
          color="primary"
          intlId="yes"
          variant="contained"
          onClick={() => {
            store.runWaveStore.setAddUnsortedOrders(true);
            props.onConfirm();
          }}
        />,
        <Button
          color="primary"
          intlId="no"
          variant="outlined"
          onClick={() => {
            store.runWaveStore.setAddUnsortedOrders(false);
            props.onConfirm();
          }}
        />,
      ]}
    >
      <Text
        intlId="unsortedOrdersContent"
        intlParams={{
          numberOfOrders: unsortedOrders && unsortedOrders.unsortedOrders,
          waveName: unsortedOrders && unsortedOrders.waveName,
        }}
      />
    </Dialog>
  );
});
