import { Check, PriorityHigh } from "@mui/icons-material";
import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import { IWebsocketTableMessagesClientModelContent } from "models/client-models";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import ConfirmDialog from "wes_shell_app/dialog-confirm-button";
import useConfirmDialog from "wes_shell_app/use-confirm-dialog";
import { useFatalLogstore } from "wes_shell_app/use-fatal-log-store";
import { useDashboardStore } from "../hooks/use-dashboard-store";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "time",
  },
  {
    label: "severity",
  },
  {
    label: "message",
  },
  {
    label: "confirmed",
  },
  {
    label: "module",
  },
];

const ProcessDataTable = observer(() => {
  const store = useDashboardStore();
  const fatalLogStore = useFatalLogstore();
  const confirmDialog = useConfirmDialog();
  if (!store.isLoaded) return null;

  return (
    <>
      <ConfirmDialog
        onConfirm={() => fatalLogStore.confirmMessage(confirmDialog.item)}
      />
      <DataTable
        rows={store.processMessagesDataTable}
        hidePaging
        columns={columns}
        rowTemplate={(item) => (
          <RowTemplate
            key={item.id}
            item={item}
            onConfirm={confirmDialog.open}
          />
        )}
      />
    </>
  );
});

type IRowTemplateProps = {
  item: IWebsocketTableMessagesClientModelContent;
  onConfirm: Function;
};

const RowTemplate = (props: IRowTemplateProps) => {
  const { issued, severity, message, appmoduleName, confirmed } = props.item;
  const confirmable = severity === "FATAL" && !confirmed;
  return (
    <TableRow
      className={confirmable ? "cursor-pointer" : ""}
      onClick={() => confirmable && props.onConfirm(props.item)}
      style={confirmable ? { backgroundColor: "#FFCCCB" } : {}}
    >
      <TableCell>{new Date(issued).toLocaleString()}</TableCell>
      <TableCell>{severity}</TableCell>
      <TableCell>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </TableCell>
      <TableCell>
        {severity === "FATAL" &&
          (confirmed ? (
            <Check color="success" />
          ) : (
            <PriorityHigh color="error" />
          ))}
      </TableCell>
      <TableCell>{appmoduleName}</TableCell>
    </TableRow>
  );
};

export default ProcessDataTable;
