import { IClientOrderModel } from "models/client-models";
import { IAPIMessageModel, IAPIOrderModel } from "models/server-models";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import {
  OrdersDataTableProvider,
  IOrdersFilterParams,
} from "../data/orders-data-table-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { deleteItem } from "wes_shell_app/api-utils";
import { makeObservable, action } from "mobx";
import { environment } from "environment";
import alerts from "wes_shell_app/alerts";
import { serverToClientOrderModel } from "models/mappers";

export class OrdersViewStore extends BaseTableViewStore<
  IAPIOrderModel,
  IClientOrderModel,
  IOrdersFilterParams,
  OrdersDataTableProvider
> {
  constructor() {
    super(new OrdersDataTableProvider());

    makeObservable(this, {
      setCurrentWaveId: action,
    });
  }

  private readonly appContext = new SharedContextStore();

  deleteOrder = async (id: number) => {
    try {
      await deleteItem(environment.serviceApi + "ui/deleteWaveOrders", id);
      this.forceReload();
    } catch (error) {
      alerts.error({
        message: "Error deleting order:",
      });
    }
  };

  setCurrentWaveId = (id: number) => {
    this.tableProvider.currentWaveId = id;
    this.forceReload();
  };

  mapServerToClientModel = (item): IClientOrderModel =>
    serverToClientOrderModel(item);

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
}
