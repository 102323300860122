import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import SharedContextStore from "wes_shell_app/shared-context-store";
import SortedPiecesChart from "../components/sorted-pieces-chart";
import SortedByUserChart from "../components/sorted-by-user-chart";
import SortedOrdersChart from "../components/sorted-orders-chart";

const ChartsView = observer(() => {
  const store = new SharedContextStore();
  const UserAccess = store.appContext.accessRights.getUserAccessEnums;

  return (
    <BaseView
      cardBackgroundColor={false}
      accessKey={UserAccess.SORTER_NAV_ITEM}
    >
      <SortedPiecesChart />
      <SortedByUserChart />
      <SortedOrdersChart />
    </BaseView>
  );
});

export default ChartsView;
