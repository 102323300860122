import { IClientWaveModel } from "models/client-models";
import { IAPIWaveModel } from "models/server-models";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import {
  WavesDataTableProvider,
  IWavesFilterParams,
} from "../data/waves-data-table-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { RunWaveStore } from "../data/run-wave-store";
import { serverToClientWaveModel } from "models/mappers";

export class WavesViewStore extends BaseTableViewStore<
  IAPIWaveModel,
  IClientWaveModel,
  IWavesFilterParams,
  WavesDataTableProvider
> {
  constructor() {
    super(new WavesDataTableProvider());
  }

  private readonly appContext = new SharedContextStore();
  readonly runWaveStore = new RunWaveStore();

  mapServerToClientModel = (item): IClientWaveModel =>
    serverToClientWaveModel(item);

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
}
