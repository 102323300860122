import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { useOrderLinesStore } from "../hooks/use-order-lines-store";
import TextInputField from "wes_shell_app/text-input-field";

export const OrderLinesDataTableFilter = observer(() => {
  const store = useOrderLinesStore();

  return (
    <Box className="flex flex-row justify-between space-x-2 w-full mt-2 mb-8">
      <Box className="w-56">
        <TextInputField
          label="externalid"
          value={store.getFilterValue("searchExternalId")}
          onChange={(v) => store.setFilter("searchExternalId", v)}
        />
      </Box>
    </Box>
  );
});
