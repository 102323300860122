import {
  Videocam,
  FiberManualRecord,
  WineBar,
  Warning,
} from "@mui/icons-material";
import { FormLabel } from "@mui/material";
import Card from "common/base-components/card/card";
import ResizableCard from "common/base-components/card/card-resizable";
import CardTable from "common/base-components/card/card-table";
import { observer } from "mobx-react";
import { getTranslation } from "wes_shell_app/localization-utils";
import LocalizedText from "wes_shell_app/localized-text";
import { SimpleCard } from "wes_shell_app/simpleCard";
import CameraImage from "./camera/camera-image";
import { useInductionStationViewStore } from "../hooks/use-induction-station-view-store";
import { IClientCameraImageModel } from "models/client-models";
import {
  IAPIBarcodeModel,
  IWebsocketNotificationRobotArrivedModel,
  IWebsocketNotificationRobotDeparturedModel,
} from "models/server-models";
import { useCallback, useEffect, useState } from "react";
import { useBarcodeScanner } from "../hooks/use-barcode-scanner";
import alerts from "wes_shell_app/alerts";
import { Dialog } from "common/base-components/dialog/dialog";
import Big from "wes_shell_app/big";

type IndupointDetailType = {
  id: number;
  indupointName?: string;
};

const IndupointDetail = observer(
  ({ id, indupointName }: IndupointDetailType) => {
    const store = useInductionStationViewStore();
    const barcodeInfo: IAPIBarcodeModel = store.getBarcodeInfo(id);
    const forceUpdate = store.getForceUpdate();
    const [actionStyles, setActionStyles] = useState({
      background: "",
      color: "",
    });

    const getFillingDirectionText = (
      arrived: IWebsocketNotificationRobotArrivedModel,
      departured: IWebsocketNotificationRobotDeparturedModel
    ): string => {
      if (arrived) {
        return `${getTranslation("robotArrived")} - ${arrived.robotid}`;
      } else if (departured) {
        return `${getTranslation("robotDepartured")} - ${departured.robotid}`;
      }
      return "";
    };

    const getCardBackgroundColor = (
      arrived: IWebsocketNotificationRobotArrivedModel,
      departured: IWebsocketNotificationRobotDeparturedModel
    ) => {
      if (arrived) return "#a5d6a7";
      if (departured) return "#a5d6a7";
      return "#fff";
    };

    const getActionStyles = useCallback((action: string) => {
      const styles = {
        "robot-sort": { background: "#a5d6a7", color: "black" },
        "drop-to-buffer": { background: "#a5d6a7", color: "black" },
        "go-to-hospital": { background: "#ffcc80", color: "black" },
        "manual-sort": { background: "#fff59d", color: "black" },
        discard: { background: "#fff59d", color: "black" },
      };
      return styles[action] || { background: "#fff", color: "black" };
    }, []);

    useEffect(() => {
      setActionStyles(getActionStyles(store.getBarcodeInfo(id)?.action));
    }, [getActionStyles, id, store]);

    useEffect(() => {
      if (barcodeInfo) {
        if (
          barcodeInfo.action === "go-to-hospital" ||
          barcodeInfo.action === "drop-to-buffer"
        ) {
          store.setBarcodeScannerHospitalDialog(true, id);
        } else {
          store.setBarcedeScanneGoToHospitalBuffer(false, id);
        }
      }
    }, [barcodeInfo, id, store]);

    useEffect(() => {
      if (!store.getBarcodeScannerHospitalDialog(id)?.state && barcodeInfo) {
        if (store.getRobotReadyForSort(id).state) {
          if (store.getBarcodeScanneGoToHospitalBuffer(id).state) {
            if (
              (barcodeInfo.action === "drop-to-buffer" ||
                barcodeInfo.action === "go-to-hospital") &&
              !store.getBarcodeScannerReset(id).state
            ) {
              useBarcodeScanner(store, id);
            } else {
              useBarcodeScanner(store, id, "no-sort");
            }
            store.setBarcodeScannerReset(false, id);
          } else {
            useBarcodeScanner(store, id);
          }
        } else {
          alerts.error({ message: getTranslation("robotNotReady") });
        }
      }
    }, [barcodeInfo, forceUpdate, id, store]);

    const goToHospitalBufferHandler = (value: boolean) => {
      value || store.setBarcodeScannerReset(true, id);
      store.setBarcedeScanneGoToHospitalBuffer(true, id);
      store.setForceUpdate();
      store.setBarcodeScannerHospitalDialog(false, id);
      store.resetBarcodeScannerHospitalDialog(id);
    };

    const {
      action = "",
      lines: [
        {
          weight = 0,
          height = 0,
          length = 0,
          width = 0,
          fragile = false,
          hazmat = false,
          barcode = "",
        } = {},
      ] = [{}],
    } = barcodeInfo || {};

    const {
      name: articleName,
      externalid,
      destination,
      phyname,
      phyaddress,
      phylocation,
    } = store.getSort(id) || {};

    const camera = store.getCamera(id);

    const propertiesIcon = fragile ? (
      <WineBar sx={{ fontSize: "70px" }} />
    ) : hazmat ? (
      <Warning sx={{ fontSize: "70px" }} />
    ) : null;

    const handleCameraChange = (
      camera: IClientCameraImageModel,
      value: boolean
    ) => {
      value
        ? store.setCameraImageAllowed(camera, true)
        : store.setCameraImageAllowed(camera, false);
    };

    const notificationInfoRobotArrived = store.getRobotArrived(id);
    const notificationInfoRobotDepartured = store.getRobotDepartured(id);

    return (
      <>
        <Dialog
          open={store.getBarcodeScannerHospitalDialog(id)?.state}
          confirmAction={() => goToHospitalBufferHandler(true)}
          cancelAction={() => goToHospitalBufferHandler(false)}
          onClose={() => {
            goToHospitalBufferHandler(false);
            store.setBarcodeScannerHospitalDialog(false, id);
          }}
          disableEscapeKeyDown={true}
          cannotClosed={true}
        >
          {barcodeInfo?.action === "go-to-hospital"
            ? getTranslation("goToHospital")
            : getTranslation("dropToBuffer")}
        </Dialog>
        <div className="absolute left-28 top-5">
          <Big className="font-bold" text={indupointName} />
        </div>
        <div className="flex gap-2">
          <div className="w-full">
            <SimpleCard
              className={`shadow-none border border-gray-300`}
              minHeight="8rem"
            >
              <Card intlId={"barcode"} mainText={barcode} />
            </SimpleCard>
            <SimpleCard
              className={`shadow-none border border-gray-300`}
              minHeight="8rem"
            >
              <Card
                intlId={"name"}
                mainText={articleName}
                subTextSecond={externalid}
              />
            </SimpleCard>
          </div>
          <ResizableCard
            minWidth="7%"
            maxWidth={store.actualIndupoints.length === 1 ? "330px" : "50%"}
            icon={<Videocam />}
            simpleCardClassName="shadow-none border border-gray-300"
            checked={camera?.cameraImageAllowed}
            onChange={(value) => handleCameraChange(camera, value)}
            size={
              camera?.cameraImageAllowed
                ? store.actualIndupoints.length === 1
                  ? "330px"
                  : "330px"
                : "7%"
            }
            children={
              camera?.cameraImageAllowed && (
                <>
                  {camera?.imageName && (
                    <div className="absolute">
                      <FiberManualRecord
                        style={{
                          color: !camera?.barcodeScanned
                            ? "#D32F2F"
                            : "#009000",
                        }}
                      />
                    </div>
                  )}
                  <CameraImage
                    base64String={camera?.imageData}
                    error={camera?.error}
                  />
                </>
              )
            }
          />
        </div>
        <div className="flex gap-2">
          <SimpleCard
            className={`max-w-[20%] shadow-none border border-gray-300`}
            minHeight="6rem"
          >
            <Card intlId={"sorterOutput"} mainText={destination} />
          </SimpleCard>
          <SimpleCard
            className="shadow-none border border-gray-300"
            minHeight="6rem"
          >
            <Card
              intlId={"physicalLocation"}
              mainText={phyname}
              subTextFirst={phyaddress}
              subTextSecond={phylocation}
            />
          </SimpleCard>
          <SimpleCard
            className={`max-w-[20%] shadow-none border border-gray-300`}
            minHeight="6rem"
          >
            {fragile || hazmat ? (
              <Card intlId={"properties"} icon={propertiesIcon} />
            ) : (
              <Card intlId={"properties"} />
            )}
          </SimpleCard>
        </div>
        <div className="flex gap-2"></div>
        <div className="flex gap-2">
          <SimpleCard
            backgroundColor={actionStyles.background}
            className="shadow-none border border-gray-300"
            minHeight="6rem"
          >
            <Card
              intlId={"sortingAction"}
              mainText={action}
              className={"flex justify-center"}
            />
          </SimpleCard>
          <SimpleCard
            className="shadow-none border border-gray-300"
            minHeight="6rem"
            backgroundColor={getCardBackgroundColor(
              notificationInfoRobotArrived,
              notificationInfoRobotDepartured
            )}
          >
            <Card
              intlId={"fillingDirection"}
              mainText={getFillingDirectionText(
                notificationInfoRobotArrived,
                notificationInfoRobotDepartured
              )}
            />
          </SimpleCard>
        </div>
        <div className="flex gap-2">
          <SimpleCard
            className="shadow-none border border-gray-300"
            minHeight="6.7rem"
          >
            <FormLabel className="text-xs" sx={{ fontSize: "0.75em" }}>
              <LocalizedText intlId={"barcodeInfo"} />
            </FormLabel>
            <div className="flex gap-6">
              <CardTable
                title={[getTranslation("weight"), getTranslation("height")]}
                data={[weight, height]}
                sufix={["kg", "m"]}
              />
              <CardTable
                title={[getTranslation("length"), getTranslation("width")]}
                data={[length, width]}
                sufix={["m", "m"]}
              />
            </div>
          </SimpleCard>
        </div>
      </>
    );
  }
);

export default IndupointDetail;
