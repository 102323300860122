/**
 * Mapps data comming from server API to client models
 */

import * as serverModels from "./server-models";
import * as clientModels from "./client-models";
import formatters from "wes_shell_app/localization-utils";

export const serverToClientHeaderInfoModel = (
  item: serverModels.IWebSocketHeaderInfoModel
): clientModels.IClientHeaderInfoModel => ({
  ...item,
  itemsFullnessArray: [item.sortedItems, item.totalItems],
  ordersFullnessArray: [item.sortedOrders, item.totalOrders],
  bufferFullnessArray: [item.bufferItems],
  hospitalFullnessArray: [item.hospitalItems],
  headerInfoStartedDate: item.started
    ? formatters.formatDate(item.started)
    : "-",
  waveID: item.id ? item.id : "-",
});

export const serverToClientFatalTableMessageContent = (
  item: serverModels.IWebsocketTableMessagesServerModelContent,
  clientModule: string
): clientModels.IWebsocketFatalTableMessagesClientModelContent => ({
  ...item,
  appmoduleName: clientModule || "--",
  warehouseId: 0,
  warehouseName: "",
  stationId: 0,
  stationName: "",
  applicationUuid: "",
  applicationName: "",
  type: "",
});

export const serverToClientTableMessageContent = (
  item: serverModels.IWebsocketTableMessagesServerModelContent,
  clientModule: string
): clientModels.IWebsocketTableMessagesClientModelContent => ({
  ...item,
  appmoduleName: clientModule || "--",
});

export const serverToClientHealthModel = (
  item: serverModels.IWebSocketHealthModel
): clientModels.IClientHealthModel => ({
  ...item,
  healthID: item.id ? item.id : "-",
});

export const serverToClientWaveControlModel = (
  item: serverModels.IWebsocketWaveControlModel
): clientModels.IClientWaveControlModel => ({
  ...item,
});

export const serverToClientWaveInfoModel = (
  item: serverModels.IAPIWaveInfoModel
): clientModels.IClientWaveInfoModel => ({
  ...item,
});

export const serverToClientWaveCorrectionModel = (
  item: serverModels.IServerWaveCorrectionsModel
): clientModels.IClientWaveCorrectionModel => ({
  ...item,
  created: formatters.formatDate(item.created),
  updated: formatters.formatDate(item.updated),
  inducted: formatters.formatDateTime(item.inducted),
  sorted: formatters.formatDateTime(item.sorted),
});

export const serverToClientWaveModel = (
  item: serverModels.IAPIWaveModel
): clientModels.IClientWaveModel => ({
  ...item,
  started: formatters.formatDateTime(item.started),
  completed: formatters.formatDateTime(item.completed),
  created: formatters.formatDateTime(item.created),
});

export const serverToClientOrderModel = (
  item: serverModels.IAPIOrderModel
): clientModels.IClientOrderModel => ({
  ...item,
  ordered: formatters.formatDateTime(item.ordered),
  completed: formatters.formatDateTime(item.completed),
  created: formatters.formatDateTime(item.created),
});

export const serverToClientOrderLineModel = (
  item: serverModels.IAPIOrderLineModel
): clientModels.IClientOrderLineModel => ({
  ...item,
  created: formatters.formatDateTime(item.created),
  updated: formatters.formatDateTime(item.updated),
  sorted: formatters.formatDateTime(item.sorted),
});

export const serverToClientSortPlansModel = (
  item: serverModels.IServerRequestSortPlansModel
): clientModels.IClientSortPlanModel => ({
  ...item,
  created: formatters.formatDateTime(item.created),
  updated: formatters.formatDateTime(item.updated),
});

export const serverToClientInduPiontsModel = (
  item: serverModels.IAPIInductionStationInductionPointContent
): clientModels.IClientInduPiontsModel => ({
  ...item,
  opened: false,
});
