import { FiberManualRecord } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { SettingsViewStore } from "../stores/settings-view-store";
import { Checkbox } from "wes_shell_app/checkbox";
import Button from "wes_shell_app/button";
import { useState } from "react";
import Text from "wes_shell_app/text";
import SettingsRestartModuleDialog from "./dialogs/settings-restart-module-dialog";
import SettingsRestartAppDialog from "./dialogs/settings-restart-app-dialog";

type RestartModuleProps = {
  store: SettingsViewStore;
};

const RestartModule = observer((props: RestartModuleProps) => {
  const { store } = props;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDialogLogout, setShowDialogLogout] = useState<boolean>(false);

  const onChange = (checked: boolean, id: number) => {
    if (checked) {
      store.setSelectedModules(id);
    } else {
      store.unselectedModules(id);
    }
  };

  const healthIcon = (health: string) => {
    if (health === "normal") {
      return <FiberManualRecord style={{ color: "#009000" }} />;
    } else if (health === "emergency") {
      return <FiberManualRecord style={{ color: "#D32F2F" }} />;
    }
  };

  const selectAllModules = () => {
    store.resetSelectedModules();
    store.modules.forEach((component) => {
      store.setSelectedModules(component.id);
    });
    setShowDialog(true);
  };

  const restartModules = async () => {
    await store.restartModules();
    setShowDialog(false);
    setShowDialogLogout(true);
  };

  return (
    <>
      <SettingsRestartModuleDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onConfirm={restartModules}
      />
      <SettingsRestartAppDialog
        open={showDialogLogout}
        onClose={() => setShowDialogLogout(false)}
      />
      <div className="flex flex-col space-y-6">
        {store.modules.length > 0 ? (
          store.modules.map((component) => (
            <div
              key={component.id}
              className="flex items-center justify-between"
            >
              <Checkbox
                value={store.selectedModules.includes(component.id)}
                label={component.name}
                onChange={(v) => onChange(v, component.id)}
              />
              <span>{healthIcon(component.health)}</span>
            </div>
          ))
        ) : (
          <Text intlId="componentsNoToTest" />
        )}
        <Button
          variant="contained"
          intlId="modulsRestartSelected"
          onClick={() => setShowDialog(true)}
          disabled={store.selectedModules.length === 0}
        />
        <Button
          variant="contained"
          intlId="modulsRestartAll"
          onClick={selectAllModules}
        />
      </div>
    </>
  );
});

export default RestartModule;
