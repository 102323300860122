import { runInAction } from "mobx";
import { DashboardViewStore } from "features/dashboard/stores/dashboard-view-store";
import { InductionStationViewStore } from "features/induction-station/stores/induction-station-view-store";
import { WavesViewStore } from "features/waves/stores/view/waves-view-store";
import { OrdersViewStore } from "features/wave-detail/stores/view/orders-view-store";
import { OrderLinesViewStore } from "features/wave-detail/stores/view/order-lines-view-store";
import { SortPlansViewStore } from "features/sort-plans/stores/sort-plans-view-store";
import { DetailSortPlansViewStore } from "features/sort-plan-detail/stores/detail-sort-plans-store";
import { SettingsViewStore } from "features/settings/stores/settings-view-store";
import { WaveRunningStore } from "./common/wave-running-store";
import { WaveCorrectionsViewStore } from "features/wave-corrections/stores/wave-corrections-view-store";
import { PlatformDiagramStore } from "features/platform/stores/platform-diagram-store";
import { ChartsDataProvider } from "features/charts/stores/charts-data-provider";

export class ViewStore {
  readonly dashboard = new DashboardViewStore();
  readonly charts = new ChartsDataProvider();
  readonly inductionStation = new InductionStationViewStore();
  readonly waves = new WavesViewStore();
  readonly orders = new OrdersViewStore();
  readonly orderLines = new OrderLinesViewStore();
  readonly sortPlans = new SortPlansViewStore();
  readonly detailSortPlans = new DetailSortPlansViewStore();
  readonly setting = new SettingsViewStore();
  readonly waveCorrections = new WaveCorrectionsViewStore();
  readonly platform = new PlatformDiagramStore();
}

export class CommonStore {
  readonly waveRunning = new WaveRunningStore();
}

export class RootStore {
  readonly view = new ViewStore();
  readonly common = new CommonStore();
}

export let rootStore = new RootStore();

export function initStores() {
  rootStore = runInAction(() => new RootStore());
}

export default rootStore;
