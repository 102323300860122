import { environment } from "environment";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import {
  IAPISettingComponentsContent,
  IAPISettingComponentsModel,
  IAPISettingComponentsTestModel,
  IAPISettingModulesContent,
  IAPISettingModulesModel,
} from "models/server-models";
import alerts from "wes_shell_app/alerts";
import { dataGET, updateItem } from "wes_shell_app/api-utils";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class SettingsViewStore {
  constructor() {
    makeAutoObservable(this, {
      modules: observable,
      selectedModules: observable,
      setSelectedModules: action,
      unselectedModules: action,
      components: observable,
      setSelectedComponent: action,
      selectedComponent: observable,
    });
  }

  modules: IAPISettingModulesContent[] = [];
  selectedModules: number[] = [];

  components: IAPISettingComponentsContent[] = [];
  selectedComponent: IAPISettingComponentsContent | null = null;

  async getSettingModules() {
    try {
      const response: IAPISettingModulesModel = await dataGET(
        `${environment.serviceApi}ui/getAppModules`
      );
      this.setModules(response.content);
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      console.error(errorModel.message);
    }
  }

  async getSettingComponents() {
    try {
      const response: IAPISettingComponentsModel = await dataGET(
        `${environment.serviceApi}ui/getComponents/${this.sharedContext.appContext.currentStationId}`
      );
      this.setComponents(response.content);
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      console.error(errorModel.message);
    }
  }

  async restartModules() {
    try {
      let module: string = "";
      this.selectedModules.forEach((id) => {
        module += `module=${id}&`;
      });
      const response = await updateItem(
        `${environment.serviceApi}ui/restartModules?${module}`
      );
      return response;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
      this.resetSelectedModules();
    }
  }

  async handleComponentTest(command: "start" | "stop", endStatus?: string) {
    try {
      let url = `${environment.serviceApi}ui/hardwareTests?component=${this.selectedComponent?.name}&command=${command}&sorterID=${this.selectedComponent?.sorterID}`;

      if (command === "stop" && endStatus) {
        url += `&endStatus=${endStatus}`;
      }

      const response: IAPISettingComponentsTestModel = await updateItem(url);
      return response;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
      this.resetSelectedComponent();
    }
  }

  setComponents(components: IAPISettingComponentsContent[]) {
    runInAction(() => {
      this.components = components;
    });
  }

  setModules(modules: IAPISettingModulesContent[]) {
    runInAction(() => {
      this.modules = modules;
    });
  }

  setSelectedModules(id: number) {
    runInAction(() => {
      this.selectedModules.push(id);
    });
  }

  unselectedModules(id: number) {
    runInAction(() => {
      this.selectedModules = this.selectedModules.filter((i) => i !== id);
    });
  }

  resetSelectedModules() {
    runInAction(() => {
      this.selectedModules = [];
    });
  }

  setSelectedComponent(component: IAPISettingComponentsContent) {
    runInAction(() => {
      this.selectedComponent = component;
    });
  }

  resetSelectedComponent() {
    runInAction(() => {
      this.selectedComponent = null;
    });
  }

  private readonly sharedContext: SharedContextStore = new SharedContextStore();

  get currentUserStore() {
    return this.sharedContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
}
