import { Dialog } from "common/base-components/dialog/dialog";
import WaveSelector from "common/connected-components/wave-selector";
import Button from "wes_shell_app/button";
import Text from "wes_shell_app/text";

export type SortPlansWaveSelectorVisibleProps = {
  open: boolean;
  onClose: () => void;
  store: any;
  handleSelectWaveDialog: () => void;
};

const SortPlansWaveSelectorVisible = (
  props: SortPlansWaveSelectorVisibleProps
) => {
  const { open, onClose, store, handleSelectWaveDialog } = props;

  return (
    <Dialog
      open={open}
      intlTitle="waveSelect"
      cancelAction={onClose}
      onClose={onClose}
      actions={[
        <Button
          intlId={"continue"}
          onClick={handleSelectWaveDialog}
          key={1}
          color="primary"
        />,
      ]}
    >
      <Text intlId="lauchesSorterWaveProccesing" />
      <WaveSelector required={true} store={store} />
    </Dialog>
  );
};

export default SortPlansWaveSelectorVisible;
