import { IClientOrderLineModel } from "models/client-models";
import { IAPIOrderLineModel } from "models/server-models";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import {
  OrderLinesDataTableProvider,
  IOrderLinesFilterParams,
} from "../data/order-lines-table-data-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { action, makeObservable, observable } from "mobx";
import { deleteItem } from "wes_shell_app/api-utils";
import { environment } from "environment";
import alerts from "wes_shell_app/alerts";
import { serverToClientOrderLineModel } from "models/mappers";

export class OrderLinesViewStore extends BaseTableViewStore<
  IAPIOrderLineModel,
  IClientOrderLineModel,
  IOrderLinesFilterParams,
  OrderLinesDataTableProvider
> {
  constructor() {
    super(new OrderLinesDataTableProvider());
    makeObservable(this, {
      isDrawerOpened: observable,
      showDrawer: action,
      closeDrawer: action,
      setCurrentOrderId: action,
    });
  }

  private readonly appContext = new SharedContextStore();

  isDrawerOpened: boolean;
  showDrawer = () => (this.isDrawerOpened = true);
  closeDrawer = () => (this.isDrawerOpened = false);

  deleteLine = async (id: number) => {
    try {
      await deleteItem(environment.serviceApi + "ui/deleteOrderLines", id);
      this.forceReload();
    } catch (error) {
      alerts.error({
        message: "Error deleting order line: " + id,
      });
    }
  };

  setCurrentOrderId = (id: number) => {
    this.tableProvider.currentOrderId = id;
    this.forceReload();
  };

  mapServerToClientModel = (item): IClientOrderLineModel =>
    serverToClientOrderLineModel(item);

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
}
