import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import WaveInfo from "../components/wave-info";
import { useCallback, useEffect } from "react";
import { useInductionStationViewStore } from "../hooks/use-induction-station-view-store";
import Indupoints from "../components/indupoints";
import { useNavigationStore } from "wes_shell_app/use-navigation-store";

type InductionStationViewType = {
  id: number;
};

const InductionStationView = observer(({ id }: InductionStationViewType) => {
  const store = useInductionStationViewStore();
  const { drawer, setShowListSubheader } = useNavigationStore();

  const handleInductionState = useCallback(async () => {
    await store.closeAllIndupoints();
  }, [store]);

  useEffect(() => {
    drawer.closeDrawer();
    setShowListSubheader(false);
  }, []);

  useEffect(() => {
    store.openConnection();
    return () => {
      handleInductionState();
      store.closeConnection();
      store.resetAllIndupoints();
    };
  }, [handleInductionState, store, id]);

  useEffect(() => {
    if (store.isInductionStationsLoaded) {
      store.setActualInductionStation(id);
    }
  }, [store.isInductionStationsLoaded, id, store]);

  return (
    <BaseView cardBackgroundColor={false} heightFull={true}>
      <WaveInfo />
      <Indupoints />
    </BaseView>
  );
});

export default InductionStationView;
