import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { useOrdersStore } from "../hooks/use-orders-store";
import TextInputField from "wes_shell_app/text-input-field";

export const OrdersDataTableFilter = observer(() => {
  const store = useOrdersStore();

  return (
    <Box className="flex flex-row space-x-2 w-full mt-2 mb-8">
      <Box className="w-56">
        <TextInputField
          label="externalid"
          value={store.getFilterValue("searchExternalId")}
          onChange={(v) => store.setFilter("searchExternalId", v)}
        />
      </Box>
      <Box className="w-56">
        <TextInputField
          label="line"
          value={store.getFilterValue("searchLine")}
          onChange={(v) => store.setFilter("searchLine", v)}
        />
      </Box>
    </Box>
  );
});
