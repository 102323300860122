import { environment } from "environment";
import TimeChartDataProvider from "wes_shell_app/time-chart-data-provider";

class ChartsCommonDataProvider extends TimeChartDataProvider {
  protected get serviceApiUrl() {
    return environment.serviceApi;
  }
  protected get endpointUrl() {
    return `ui/graph/${this.stationId}`;
  }
}

export class ChartsDataProvider {
  readonly sortedPieces = new ChartsCommonDataProvider([
    { name: "Total Items Sorted" },
    { name: "Items Passed through Hospital" },
    { name: "Items Sorted Manually" },
  ]);
  readonly sortedByUser = new ChartsCommonDataProvider([
    { name: "Items Sorted by User", tagName: "userName" },
  ]);
  readonly sortedOrders = new ChartsCommonDataProvider([
    { name: "Total Orders Completed" },
  ]);
}
