import { IAPISortModel } from "models/server-models";
import { dataPOST } from "wes_shell_app/api-utils";
import alerts from "wes_shell_app/alerts";
import { environment } from "environment";
import { IAPISortRequestModel } from "common/types/types";
import { getTranslation } from "wes_shell_app/localization-utils";
import { InductionStationViewStore } from "../stores/induction-station-view-store";

export async function useBarcodeScanner(
  store: InductionStationViewStore,
  id: number,
  action?: string
) {
  const barcodeInfo = store.getBarcodeInfo(id);

  if (!store.getRobotReadyForSort(id)) {
    alerts.error({ message: getTranslation("robotNotReady") });
    return;
  }

  action =
    barcodeInfo?.errno === 24 || barcodeInfo?.errno === 23 ? "no-sort" : action;

  const sortRequest: IAPISortRequestModel = {
    message: "sortBarcode",
    waveid: store.waveInfo?.waveid,
    station: store.actualInductionStation?.inductionName,
    indupoint:
      store.getRobotArrived(id)?.indupoint ||
      store.getRobotDepartured(id)?.indupoint,
    indupointId:
      store.getRobotArrived(id)?.indupointId ||
      store.getRobotDepartured(id)?.indupointId,
    barcode: !action ? barcodeInfo?.lines[0]?.barcode : false,
    quantity: 1.0,
    action: action ? action : barcodeInfo.action,
    reason: barcodeInfo.reason || "normal",
  };

  try {
    const response = await dataPOST<IAPISortRequestModel, IAPISortModel>(
      `${environment.inductionStationApi}sort`,
      sortRequest
    );
    store.setSort(response, id);
    store.resetHandleHandScanBarcode();
    store.resetScannedCode();
  } catch (error) {
    const fixedErrorMessage = (error.message as string).replace("Error: ", "");
    const errorModel = JSON.parse(fixedErrorMessage);
    alerts.error({ message: errorModel.message, decode: true });
    store.resetScannedCode();
  }
}
