import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { getTranslation } from "wes_shell_app/localization-utils";
import { useOrderLinesStore } from "../hooks/use-order-lines-store";
import { IClientOrderLineModel } from "models/client-models";
import { WineBar, Delete, Warning } from "@mui/icons-material";
import { OrderLinesViewStore } from "../stores/view/order-lines-view-store";
import ConfirmDialog from "wes_shell_app/confirm-dialog";
import useConfirmDialog from "wes_shell_app/use-confirm-dialog";
import Button from "wes_shell_app/button";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "id",
  },
  {
    label: "externalid",
  },
  {
    label: "name",
  },
  {
    label: "barcode",
  },
  {
    label: "state",
  },
  {
    label: "qty",
    justify: "end",
  },
  {
    label: "bufferqty",
    justify: "end",
  },
  {
    label: "inprocessqty",
    justify: "end",
  },
  {
    label: "sortedqty",
    justify: "end",
  },
  {
    label: "inductionqty",
    justify: "end",
  },
  {
    label: "hospitalqty",
    justify: "end",
  },
  {
    label: "remainingqty",
    justify: "end",
  },
  {
    label: "uom",
  },
  {
    label: "hazmat",
  },
  {
    label: "fragile",
  },
  {
    label: "weight",
    justify: "end",
  },
  {
    label: "length",
    justify: "end",
  },
  {
    label: "width",
    justify: "end",
  },
  {
    label: "height",
    justify: "end",
  },
  {
    label: "sorted",
  },
  {
    label: "created",
  },
  {
    label: "updated",
  },
  {
    label: "",
  },
];

export const OrderLinesDataTable = observer(() => {
  const store = useOrderLinesStore();
  const confirmDialog = useConfirmDialog();
  if (!store.isLoaded) return null;

  return (
    <>
      <ConfirmDialog
        onConfirm={() => store.deleteLine(confirmDialog.item.id)}
      />
      <DataTable
        page={store.page}
        isLoading={!store.isLoaded}
        onPageChange={store.setPage}
        activeSortProperty={store.sortBy}
        onSortChange={store.setSort}
        onDirectionChange={store.setDirection}
        direction={store.direction}
        pageCount={store.totalCount}
        rows={store.items}
        pageSize={store.pageSize}
        onPageSizeChange={store.setPageSize}
        columns={columns}
        rowTemplate={(item) => (
          <RowTemplate
            key={item.id}
            item={item}
            store={store}
            dialog={confirmDialog}
          />
        )}
      />
    </>
  );
});

type IRowTemplateProps = {
  item: IClientOrderLineModel;
  store: OrderLinesViewStore;
  dialog: { open: (item) => void };
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const {
    id,
    state,
    name,
    externalid,
    created,
    updated,
    sorted,
    barcode,
    quantity,
    sortedqty,
    inductionqty,
    hospitalqty,
    remainingqty,
    bufferqty,
    inprocessqty,
    hazmat,
    fragile,
    weight,
    length,
    width,
    height,
    uom,
  } = props.item;
  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{externalid}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{barcode}</TableCell>
      <TableCell>{getTranslation(state + "State")}</TableCell>
      <TableCell align="right">{quantity}</TableCell>
      <TableCell align="right">{bufferqty}</TableCell>
      <TableCell align="right">{inprocessqty}</TableCell>
      <TableCell align="right">{sortedqty}</TableCell>
      <TableCell align="right">{inductionqty}</TableCell>
      <TableCell align="right">{hospitalqty}</TableCell>
      <TableCell align="right">{remainingqty}</TableCell>
      <TableCell>{uom}</TableCell>
      <TableCell>{hazmat && <Warning color="error" />}</TableCell>
      <TableCell>{fragile && <WineBar color="error" />}</TableCell>
      <TableCell align="right">{weight}</TableCell>
      <TableCell align="right">{length}</TableCell>
      <TableCell align="right">{width}</TableCell>
      <TableCell align="right">{height}</TableCell>
      <TableCell>{sorted}</TableCell>
      <TableCell>{created}</TableCell>
      <TableCell>{updated}</TableCell>
      <TableCell>
        {props.store.isAdmin && props.item.state === "created" && (
          <Button
            variant="icon"
            icon={<Delete color="error" />}
            onClick={() => props.dialog.open(props.item)}
          />
        )}
      </TableCell>
    </TableRow>
  );
});
