import { observer } from "mobx-react";
import { FiberManualRecord } from "@mui/icons-material";
import { useDashboardStore } from "../hooks/use-dashboard-store";
import { Divider } from "@mui/material";
import Text from "wes_shell_app/text";

const setIcon = (health) => {
  if (health === "normal") {
    return <FiberManualRecord style={{ color: "#009000" }} />;
  } else if (health === "emergency") {
    return <FiberManualRecord style={{ color: "#D32F2F" }} />;
  }
};

const InfoRow = ({ label, value, className = "" }) => (
  <div className={`grid grid-cols-5 gap-1 ${className}`}>
    <div className="col-start-1 col-span-3">{label}</div>
    <div className="font-medium col-start-4 col-span-6">{value}</div>
  </div>
);

const HealthCheck = observer(() => {
  const store = useDashboardStore();

  if (!store.isLoaded) return null;

  return store.healthMessagesDataTable.length > 0 ? (
    <div className="grid grid-cols-1 gap-2">
      {store.healthMessagesDataTable.map(
        (x, i) =>
          x.name.startsWith("swes-") && (
            <InfoRow
              key={x.id}
              label={x.name.replace("swes-", "")}
              value={setIcon(x.health)}
            />
          )
      )}
    </div>
  ) : (
    <div className="mt-4">
      <Divider />
      <div className="flex w-full justify-center my-1.5">
        <Text intlId="noRecords" />
      </div>
      <Divider />
    </div>
  );
});

export default HealthCheck;
