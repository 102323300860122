import { useState, useEffect, useRef, useCallback } from "react";
import { useInductionStationViewStore } from "../hooks/use-induction-station-view-store";
import { IWebsocketBarcodeReadInfoModel } from "models/server-models";
import alerts from "wes_shell_app/alerts";
import { getTranslation } from "wes_shell_app/localization-utils";
import useForbiddenEventCode from "../hooks/use-forbidden-event-code";
import { useNavigate, useLocation } from "react-router-dom";
import { basePath, RoutePaths } from "routing/menu";

export enum BarcodeAction {
  yes = "YES",
  no = "NO",
  ok = "OK",
  sort = "SORT",
  open = "OPEN",
  close = "CLOSE",
  closeStation = "CLOSESTATION",
  handScanBarcode = "HANDSCANBARCODE",
}

export const useHandBarcodeScanner = () => {
  const store = useInductionStationViewStore();
  const [scannedBarcode, setScannedBarcode] = useState<string | null>(null);
  const [timer, setTimer] = useState<number | null>(null);
  const forbiddenEventCode = useForbiddenEventCode();
  const forbiddenEventCodeRef = useRef<string[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    forbiddenEventCodeRef.current = forbiddenEventCode;
  }, [forbiddenEventCode]);

  useEffect(() => {
    if (scannedBarcode) {
      const model: IWebsocketBarcodeReadInfoModel = {
        sorterid: store.actualInductionStation?.sorterID,
        barcodes: [scannedBarcode],
        timestamp: new Date(),
        indupointId: parseInt(store.handleHandScanBarcode[1]),
      };
      store.setbarCodeReadInfo(model);
      store.resetHandleHandScanBarcode();
      setScannedBarcode(null);
    }
  }, [scannedBarcode, store]);

  const matchesCurrentPath = (
    path: string,
    allowNumberSuffix: boolean = false
  ) => {
    if (allowNumberSuffix) {
      const regex = new RegExp(`^${basePath}${path}/\\d+$`);
      return regex.test(location.pathname);
    }
    return `${basePath}${path}` === location.pathname;
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        !matchesCurrentPath(RoutePaths.home) &&
        !matchesCurrentPath(`${RoutePaths.inductionStation}`, true)
      ) {
        return;
      }

      if (document.activeElement === inputRef.current) {
        return;
      }

      const { key, code } = event;
      const isForbiddenEventCode = forbiddenEventCodeRef.current.includes(code);
      const isAlphanumeric = /^[a-zA-Z0-9;]$/.test(key);

      if (isForbiddenEventCode && !isAlphanumeric) {
        alerts.error({ message: getTranslation("wrongKeyEnter") });
      } else if (isAlphanumeric) {
        store.setScannedCode(key);
      } else {
        return;
      }

      if (timer) {
        clearInterval(timer);
      }

      const newTimer = window.setInterval(() => {
        clearInterval(newTimer);
        const scannedCode = store.scannedCode.join("").toUpperCase();

        if (store.runningScanTest) {
          store.setTestBarcodes([scannedCode]);
          return;
        }

        if (matchesCurrentPath(RoutePaths.home) && store.isUser) {
          if (scannedCode.startsWith(BarcodeAction.open)) {
            const codes = scannedCode.split(";");
            if (!codes[1]) {
              return;
            }
            navigate(`${basePath}${RoutePaths.inductionStation}/${codes[1]}`);
            store.setActualInductionStation(parseInt(codes[2]));
            store.setIndupointsState(
              parseInt(codes[2]),
              store.getIndupointName(parseInt(codes[2])),
              "open"
            );
          }
        } else {
          if (scannedCode.includes(";")) {
            const codes = scannedCode.split(";");
            switch (codes[0]) {
              case BarcodeAction.sort:
                store.setHandleHandScanBarcode(codes);
                break;
              case BarcodeAction.open:
                navigate(
                  `${basePath}${RoutePaths.inductionStation}/${codes[1]}`
                );
                store.setActualInductionStation(parseInt(codes[2]));
                store.setIndupointsState(
                  parseInt(codes[2]),
                  store.getIndupointName(parseInt(codes[2])),
                  "open"
                );
                break;
              case BarcodeAction.close:
                store.setIndupointsState(
                  parseInt(codes[2]),
                  store.getIndupointName(parseInt(codes[2])),
                  "close"
                );
                break;
              case BarcodeAction.closeStation:
                navigate(`${basePath}${RoutePaths.home}`);
                break;
              default:
                break;
            }
          } else {
            /* If only one indupoint is open, there is no need to enter the location of the indupoint before the scan */
            store.actualIndupoints.filter((item) => item.opened).length < 2 &&
              store.setHandleHandScanBarcode([
                BarcodeAction.sort,
                store.actualIndupoints[0].indupointId.toString(),
              ]);
            if (
              store.handleHandScanBarcode.length > 0 &&
              store.handleHandScanBarcode[0] === BarcodeAction.sort
            ) {
              const id = parseInt(store.handleHandScanBarcode[1]);
              if (store.getBarcodeScannerHospitalDialog(id)) {
                if (
                  scannedCode === BarcodeAction.yes ||
                  scannedCode === BarcodeAction.ok
                ) {
                  goToHospitalBufferHandler(true, id);
                } else if (scannedCode === BarcodeAction.no) {
                  goToHospitalBufferHandler(false, id);
                }
              } else {
                if (store.scannedCode.length > 2) {
                  setScannedBarcode(scannedCode);
                }
              }
            } else {
              if (scannedCode.length > 1) {
                alerts.error({
                  message: getTranslation("beforeSortingSelectIndupoint"),
                });
              }
            }
          }
        }
        store.resetScannedCode();
      }, 1000);

      setTimer(newTimer);
    },
    [store, timer, location, navigate]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const goToHospitalBufferHandler = useCallback(
    (value: boolean, id: number) => {
      value || store.setBarcodeScannerReset(true, id);
      store.setBarcedeScanneGoToHospitalBuffer(true, id);
      store.setBarcodeScannerHospitalDialog(false, id);
      store.setForceUpdate();
      store.resetBarcodeScannerHospitalDialog(id);
    },
    [store]
  );

  return {
    inputRef,
    setScannedBarcode,
  };
};
