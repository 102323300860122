import { Dialog } from "common/base-components/dialog/dialog";
import { observer } from "mobx-react";
import Button from "wes_shell_app/button";
import Text from "wes_shell_app/text";
import { useWavesStore } from "../../hooks/use-waves-store";
import { Stack } from "@mui/material";
import { IAPIChangeContainerModel } from "models/server-models";
import Small from "wes_shell_app/small";

type waveStartDialogProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ContainersToReplaceDialog = observer(
  (props: waveStartDialogProps) => {
    const store = useWavesStore();
    if (!store.isLoaded) return null;

    const containersToReplace = store.runWaveStore.containersToReplace;
    const refreshDialog = async () => {
      await store.runWaveStore.refreshContainersToReplace();
      if (!containersToReplace) props.onConfirm();
    };

    return (
      <Dialog
        intlTitle="unchangedContainersTitle"
        open={props.isOpened}
        onClose={props.onClose}
        actions={[
          <Button
            color="primary"
            intlId="refresh"
            variant="contained"
            onClick={refreshDialog}
          />,
        ]}
      >
        <Text intlId="unchangedContainersDesc" />
        <Stack>
          {containersToReplace &&
            containersToReplace.map((item) => <RowTemplate item={item} />)}
        </Stack>
      </Dialog>
    );
  }
);

type RowTemplateProps = {
  item: IAPIChangeContainerModel;
};
const RowTemplate = observer((props: RowTemplateProps) => {
  const { name, container } = props.item;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      paddingLeft="20%"
      paddingRight="20%"
    >
      <Small intlId={name || ""} />
      <Small intlId={container || ""} />
    </Stack>
  );
});
