import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import {
  IAPIPhysLocation,
  IAPIPhysLocationContent,
  IServerDetailSortPlansModel,
} from "models/server-models";
import {
  IClientDetailSortPlanModel,
  IClientSortPlanModel,
} from "models/client-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { computed, makeObservable, observable } from "mobx";
import { DetailSortPlansDataTableProvider } from "./data/detail-sort-plans-data-table-provider";
import { IDetailSortPlansFilterParams } from "../utils/detail-sort-plan-utils";
import alerts from "wes_shell_app/alerts";
import { dataGET } from "wes_shell_app/api-utils";
import { environment } from "environment";
import { getTranslation } from "wes_shell_app/localization-utils";

export class DetailSortPlansViewStore extends BaseTableViewStore<
  IServerDetailSortPlansModel,
  IClientDetailSortPlanModel,
  IDetailSortPlansFilterParams,
  DetailSortPlansDataTableProvider
> {
  constructor() {
    super(new DetailSortPlansDataTableProvider());
    makeObservable(this, {
      currentUserStore: computed,
      isAdmin: computed,
      isLoaded: computed,
      defaultTimeFromValue: computed,
      physLocation: observable,
    });
  }

  selectedSortPlan: IClientSortPlanModel | null = null;
  physLocation: IAPIPhysLocationContent[] = [];

  private readonly appContext = new SharedContextStore();

  set setSelectSortPlan(selectedSortPlan: IClientSortPlanModel) {
    this.tableProvider.selectedSortPlan = selectedSortPlan;
    this.selectedSortPlan = selectedSortPlan;
  }

  async setPhysLocation(inductionID: number) {
    try {
      const response: IAPIPhysLocation = await dataGET(
        `${environment.serviceApi}ui/getPhysLocation/${inductionID}`
      );
      this.physLocation = [
        ...response.content,
        { id: 0, location: getTranslation("selectLocation") },
      ];
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  }

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
}
