import { IAPIInductionStationInductionContent } from "models/server-models";
import { getTranslation } from "wes_shell_app/localization-utils";
import SharedContextStore from "wes_shell_app/shared-context-store";

export const basePath = "/sorter";
export enum RoutePaths {
  home = "",
  charts = "/charts",
  waves = "/waves",
  waveDetail = "/waves/:id",
  corrections = "/corrections",
  sortPlan = "/sort-plan",
  platform = "/platform",
  inductionStation = "/induction-station/:id",
}

const store = new SharedContextStore();
const UserAccess = store.appContext.accessRights.getUserAccessEnums;

export function menu(
  inductionStations: IAPIInductionStationInductionContent[]
) {
  const menu = {
    groups: [
      {
        groupTitle: "menu",
        accessKey: UserAccess.SORTER_NAV_ITEM,
        items: [
          {
            title:
              store.appContext.accessRights.isAdminRole ||
              store.appContext.accessRights.isLeaderRole
                ? "dashboard"
                : "selectInductionStation",
            link: basePath + RoutePaths.home,
            icon: "Dashboard",
            accessKey: UserAccess.SORTER_NAV_ITEM,
          },
          {
            title: "charts",
            link: basePath + RoutePaths.charts,
            icon: "QueryStats",
            accessKey: UserAccess.SORTER_NAV_ITEM,
          },
        ],
      },
      {
        groupTitle: "control",
        accessKey: UserAccess.SORTER_NAV_ITEM,
        items: [
          {
            title: "waves",
            link: basePath + RoutePaths.waves,
            icon: "Waves",
            accessKey: UserAccess.SORTER_NAV_ITEM,
          },
          {
            title: "waveCorrections",
            link: basePath + RoutePaths.corrections,
            icon: "BorderColor",
            accessKey: UserAccess.SORTER_NAV_ITEM,
          },
          {
            title: "sortPlans",
            link: basePath + RoutePaths.sortPlan,
            icon: "FilterAlt",
            accessKey: UserAccess.SORTER_NAV_ITEM,
          },
        ],
      },
      {
        groupTitle: "platform",
        accessKey: UserAccess.SORTER_NAV_ITEM,
        items: [
          {
            title: "platform",
            link: basePath + RoutePaths.platform,
            icon: "Map",
            accessKey: UserAccess.SORTER_NAV_ITEM,
          },
        ],
      },
    ],
  };

  if (inductionStations.length > 0)
    menu.groups.push(dynamicFillingCenters(inductionStations));

  return menu;
}

function dynamicFillingCenters(
  inductionStations: IAPIInductionStationInductionContent[]
) {
  return {
    groupTitle: "inductionStation",
    accessKey: UserAccess.SORTER_NAV_ITEM_INDUCTION_STATION,
    items: inductionStations.map((inductionStation) => {
      return {
        title: getTranslation("inductionStation"),
        link:
          basePath +
          RoutePaths.inductionStation.replace(
            ":id",
            inductionStation.inductionID.toString()
          ),
        icon: "ControlCamera",
        accessKey: UserAccess.SORTER_NAV_ITEM_INDUCTION_STATION,
        fullscreen: true,
      };
    }),
  };
}
