import { environment } from "environment";
import { ISortPlansFilterParams } from "features/sort-plans/utils/sort-plans-utils";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class SortPlansDataTableProvider extends DataTableStore<
  any,
  ISortPlansFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();

  get endpointUrl(): string {
    return `ui/getSortPlans/${this.sharedContextStore.appContext.currentStationId}`;
  }
  protected serviceApiUrl = environment.serviceApi;

  get defaultFilter() {
    return new Map<any, any>([["state", "active"]]);
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setHours(timeFrom.getHours() - 1);
    return timeFrom;
  }
}
