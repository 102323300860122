import { observer } from "mobx-react";
import React from "react";
import { IModelDialogProps } from "wes_shell_app/model-dialog";
import { IClientCreateSortPlansRequestModel } from "models/client-models";
import TextInputField from "wes_shell_app/text-input-field";
import { useSortPlansStore } from "../hooks/use-sort-plans-store";
import Text from "wes_shell_app/text";

export const SortPlansCloneDialogForm = observer(
  (props: IModelDialogProps<IClientCreateSortPlansRequestModel>) => {
    const store = useSortPlansStore();

    const handlChangeInputField = (v: string) => {
      store.setSortPlansName(v);
    };

    return (
      <div className="flex flex-col justify-between space-y-4">
        <Text
          intlId="createNewCloneSortPlan"
          intlParams={{ name: store.selectedCloneSortPlan?.name }}
        />
        <TextInputField
          required
          value={store.newSortPlansName}
          label="newSortPlanName"
          onChange={(v) => {
            handlChangeInputField(v);
          }}
        />
      </div>
    );
  }
);
