import { Dialog } from "common/base-components/dialog/dialog";
import Button from "wes_shell_app/button";
import SortPlansWaveData from "./content/sort-plans-wave-data";

export type SortPlansStartDialogProps = {
  open: boolean;
  onClose: () => void;
  handleSlecetWaveDialog: () => void;
};

const SortPlansStartDialog = (props: SortPlansStartDialogProps) => {
  const { open, onClose, handleSlecetWaveDialog } = props;

  return (
    <Dialog
      open={open}
      intlTitle="startSortSummaryTitle"
      cancelAction={onClose}
      onClose={onClose}
      actions={[
        <Button
          intlId={"start"}
          onClick={handleSlecetWaveDialog}
          key={1}
          color="primary"
        />,
      ]}
    >
      <SortPlansWaveData />
    </Dialog>
  );
};

export default SortPlansStartDialog;
