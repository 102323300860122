import { environment } from "environment";
import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";
import { IServerCreateSortPlansRequestModel } from "models/server-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { dataPOST, updateItem } from "wes_shell_app/api-utils";
import { SortPlansViewStore } from "../sort-plans-view-store";
import { IClientCloneSortPlanModel } from "models/client-models";
import alerts from "wes_shell_app/alerts";

const store = new SharedContextStore();
const currentStationId = store.appContext.currentStationId;

export class SortPlansRequestDataProvider extends SingleDataStoreProvider<IServerCreateSortPlansRequestModel> {
  readonly sortPlansStore: SortPlansViewStore;

  constructor(sortPlansStore: SortPlansViewStore) {
    super();
    this.sortPlansStore = sortPlansStore;
  }

  get endpointUrl(): string {
    return `ui/createSortPlan/${currentStationId}?name=${this.sortPlansStore.newSortPlansName}`;
  }
  protected serviceApiUrl = environment.serviceApi;

  get defaultModel() {
    //TODO: workaround for initialization of store
    return {
      stationId: currentStationId,
    };
  }

  async updateSortPlanState(value: string, id: number) {
    const response = await updateItem(
      `${environment.serviceApi}ui/updateSortPlan/${id}?state=${value}`
    );
    return response;
  }
}
