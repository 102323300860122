import { Dialog } from "common/base-components/dialog/dialog";
import { observer } from "mobx-react";
import Button from "wes_shell_app/button";
import Text from "wes_shell_app/text";
import { useWavesStore } from "../../hooks/use-waves-store";

type waveStartDialogProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const PhysicalLocationsDialog = observer(
  (props: waveStartDialogProps) => {
    const store = useWavesStore();
    if (!store.isLoaded) return null;

    const physLocations = store.runWaveStore.physicalLocations;

    return (
      <Dialog
        intlTitle="locationCheck"
        open={props.isOpened}
        onClose={props.onClose}
        actions={[
          <Button
            color="primary"
            intlId="yes"
            variant="contained"
            onClick={props.onConfirm}
          />,
        ]}
      >
        <Text
          intlId="locationCheckContent"
          intlParams={{
            locationsCount: physLocations && physLocations.physLocationInWave,
            outputsCount: physLocations && physLocations.readySortingOutputs,
          }}
        />
      </Dialog>
    );
  }
);
